import { isMobileNav } from './helpers'

const Footer = function() {

	let elements, lastState

	function init() {
		elements = [...document.querySelectorAll('.footer__accordion')]
	}

	function watch() {
		const currentState = isMobileNav()

		elements.forEach(element => {
			const summary = element.querySelector('summary')

			if (currentState) {
				summary.removeAttribute('onclick')

				if (lastState !== currentState) {
					element.removeAttribute('open')
				}
			} else {
				summary.setAttribute('onclick', 'return false')
				element.setAttribute('open', true)
			}
		})

		lastState = currentState
	}

	return {
		init,
		watch
	}
}()

export default Footer
