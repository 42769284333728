import { disableBodyScrolling, enableBodyScrolling } from './helpers';
import { Base as BaseMenu, Menu, Search } from './menus';


const Header = function() {

	let elements, navMenu, searchBar, marketsMenu

	function init() {
		elements = {
			nav: {
				menu:    document.getElementById('page-nav-menu'),
				trigger: document.getElementById('page-nav-trigger')
			},
			search: {
				menu:    document.getElementById('page-search-menu'),
				trigger: document.getElementById('page-search-trigger')
			},
			markets: {
				menu:    document.getElementById('country-selection-menu'),
				trigger: document.getElementById('country-selection-trigger')
			}
		}

		if (elements.nav.menu) {
			navMenu = new Menu(elements.nav.menu, {
				trigger: elements.nav.trigger,
				await: 'transitionend',
				onBeforeShow: function() {
					disableBodyScrolling()
					if (marketsMenu && !marketsMenu.hidden) {
						marketsMenu.hide()
					}
				},
				onBeforeHide: enableBodyScrolling
			})
		}

		if (elements.search.menu) {
			searchBar = new Search(elements.search.menu, {
				trigger: elements.search.trigger,
				await: 'transitionend',
				onBeforeShow: function() {
					disableBodyScrolling()
					if (navMenu && !navMenu.hidden) {
						navMenu.hide()
					}
				},
				onBeforeHide: enableBodyScrolling
			})
		}

		if (elements.markets.menu) {
			marketsMenu = new BaseMenu(elements.markets.menu, {
				trigger: elements.markets.trigger,
				await: 'transitionend',
				onBeforeShow: disableBodyScrolling,
				onBeforeHide: enableBodyScrolling
			})
		}
	}

	return {
		init,
		get navMenu() {
			if (!navMenu) {
				console.warn('navMenu not initialized')
			}
			return {
				open: () => navMenu && navMenu.show(),
				close: () => navMenu && navMenu.hide(),
				get isOpen() {
					return navMenu ? !navMenu.hidden : false
				}
			}
		},
		get searchBar() {
			if (!searchBar) {
				console.warn('searchBar not inititalized')
			}
			return {
				open: () => searchBar && searchBar.show(),
				close: () => searchBar && searchBar.hide(),
				get isOpen() {
					return searchBar ? !searchBar.hidden : false
				}
			}
		},
		get marketsMenu() {
			if (!marketsMenu) {
				console.warn('marketsMenu not initialized')
			}
			return {
				open: () => marketsMenu && marketsMenu.show(),
				close: () => marketsMenu && marketsMenu.hide(),
				get isOpen() {
					return marketsMenu ? !marketsMenu.hidden : false
				}
			}
		}
	}
}()

export default Header
