import Menu from './menu'

class Dropover extends Menu {

	static get defaults() {
		return Object.assign({}, Menu.defaults, {
			onNavigate: () => {}
		})
	}

	constructor(element, config) {
		super(element, Object.assign({}, Dropover.defaults, config))
	}

	init() {
		super.init()

		this.element.addEventListener('click', event => {
			const level = event.target.parentElement
			if (!this.getMenuLevels().includes(level) && event.target.matches('a, button')) {
				event.preventDefault()

				const menuLevelPath = this.getMenuLevelPath(event.target)

				const getKeywordPath = elements => {
					return elements
						.map(element => ({
							id:   element.dataset.tag,
							text: element.querySelector('a').textContent
						}))
						.filter(element => element.text !== 'Alle')
				}

				const keywordPath = getKeywordPath(menuLevelPath)

				if (keywordPath.length) {
					this.config.onNavigate.apply(this, [keywordPath])
				}
				this.hide()
			}
		})
	}
}

export default Dropover
