import SmoothScroll from 'smooth-scroll'

export function throttle(func, waitTime) {
	let timeout = null
	let previous = 0

	const later = () => {
		previous = Date.now()
		timeout = null
		func()
	}

	return function() {
		const now = Date.now()
		const remaining = waitTime - (now - previous)
		if (remaining <= 0 || remaining > waitTime) {
			if (timeout) clearTimeout(timeout)
			later()
		} else if (!timeout) {
			timeout = setTimeout(later, remaining)
		}
	}
}

export function debounce(func, delay) {
	let inDebounce
	return function() {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

/**
 * Remove inline styles from an element
 * @param {HTMLElement} element Element to remove the styles from
 * @param  {...string} props    Styles to remove
 */
export function removeStyle(element, ...props) {
	props.forEach(prop => {
		element.style[prop] = ''
		if (element.getAttribute('style') === '') {
			element.removeAttribute('style')
		}
	})
}

/**
 * Local reference to the last saved scroll position
 * @prop {number} x Horizontal scroll position
 * @prop {number} y Vertical scroll position
 */
const scrollPos = {
	x: window.pageXOffset,
	y: window.pageYOffset
}

/**
 * Disables scrolling on the whole page
 */
export function disableBodyScrolling() {
	if (isMobileSafari) {
		scrollPos.x = window.pageXOffset
		scrollPos.y = window.pageYOffset

		Object.assign(document.documentElement.style, {
			top:      `-${scrollPos.y}px`,
			left:     `-${scrollPos.x}px`,
			position: 'fixed',
			width:    '100vw',
			height:   '100vh',
			overflow: 'hidden'
		})
	} else {
		document.documentElement.style.overflow = 'hidden'
	}
}

/**
 * Enables scrolling again and restores scroll position
 */
export function enableBodyScrolling() {
	if (isMobileSafari) {
		removeStyle(document.documentElement, ...[
			'top',
			'left',
			'position',
			'width',
			'height',
			'overflow'
		])
		window.scrollTo(scrollPos.x, scrollPos.y)
	} else {
		removeStyle(document.documentElement, 'overflow')
	}
}


/**
 * Checks if the user agent identifies itself as mobile Safari
 * @returns {boolean}
 */
export const isMobileSafari = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


/**
 * Gets the number of pixels from the top of the viewport that are blocked by
 * fixed or sticky elements
 * @returns {number} The total blocked height in pixels
 */
export function getBlockedHeight() {
	const blockingElements = [
		document.querySelector('.header'),
		document.querySelector('.section--sticky'),
	].filter(Boolean)

	let totalHeight = 0

	blockingElements.forEach(element => totalHeight += element.offsetHeight)

	return totalHeight
}

export function getWindowHeight() {
	return window.innerHeight
}

export function setWindowHeight() {
	document.documentElement.style.setProperty('--window-height', getWindowHeight() + 'px')
}


/**
 * Sets the number of pixels from the top of the viewport that are blocked by
 * fixed or sticky elements as CSS custom property on `documentElement`
 */
export function setBlockedHeight() {
	document.documentElement.style.setProperty('--blocked-height', getBlockedHeight() + 'px')
}


/**
 * Scrolls to an element that has `position: sticky`
 * @param {HTMLElement}          element The element that should be scrolled to
 * @param {SmoothScroll.Options} smooth  Either boolean or an object of SmoothScroll options
 */
export function scrollToStickyElement(element, smooth = false) {
	const previousElement = element.previousElementSibling
	const previousElementBounds = previousElement.getBoundingClientRect()
	const position = window.pageYOffset + previousElementBounds.bottom - element.offsetHeight

	if (smooth) {
		const scroll = new SmoothScroll()
		const options = typeof smooth === 'object' ? smooth : {}
		scroll.animateScroll(position, undefined, options)
	} else {
		window.scrollTo(window.pageXOffset, position)
	}
}


/**
 * Check for mobile nav breakpoint with `max-width`, substracting one pixel
 */
export function isMobileNav() {
	const computedStyle = getComputedStyle(document.documentElement)
	const breakpoint = computedStyle.getPropertyValue('--breakpoint-md')

	return window.matchMedia(`(max-width: calc(${breakpoint} - 1px))`).matches
}
