// import { debounce } from '../helpers'
import MenuBase from './base'

class Search extends MenuBase {
	init() {
		super.init()

		this.form = this.element.querySelector('form')
		this.input = this.element.querySelector('input')

		/*

		this.resultList = this.element.querySelector('.header__search-results')

		// Debounce keyUp handler
		this.handleKeyUp = debounce(this.handleKeyUp.bind(this), 250)

		this.input.addEventListener('keyup', this.handleKeyUp)

		*/
	}

	set loading(bool) {
		this.element.querySelector('.header__search-loading').hidden = !bool
	}

	async handleKeyUp(event) {
		const controlKeys = ['Tab', 'Shift', 'Alt', 'Control']

		if (!controlKeys.includes(event.key)) {
			this.clearSearchResults()

			const searchTerm = this.input.value

			if (!searchTerm) {
				this.clearSearchResults()
				return
			}

			this.loading = true

			const results = await this.getSearchResults(searchTerm)

			this.loading = false
			this.clearSearchResults()

			if (this.hidden) {
				return
			}

			this.resultList.hidden = false

			if (results.length) {
				results.forEach(result => {
					const listItem = this.createSearchResult(result);
					this.resultList.insertBefore(listItem, this.resultList.lastElementChild)
				})
			} else {
				const listItem = this.createSearchResult({ label: 'Keine Ergebnisse', url: '' });
				this.resultList.insertBefore(listItem, this.resultList.lastElementChild)
			}
		}
	}

	async getSearchResults(query) {
		// const res = await fetch('/search-endpoint', { query })

		const res = new Promise(resolve => {

			const delay = Math.round(Math.random() * 2000)
			const filter = results => results
			.filter(item => item.label.match(new RegExp(query, 'i')))
			.slice(0, 5)

			console.log('get fake search results', { query, delay })

			import('./_search-endpoint.json')
				.then(({ default: data }) => setTimeout(() => {
					resolve(filter(data))
				}, delay))
		})

		return await res
	}

	createSearchResult(result) {
		const listItem = document.createElement('li')
		listItem.classList.add('nav__item', 'is-search-result')

		const anchor = document.createElement('a')
		anchor.classList.add('nav__link')
		if (result.url) anchor.href = result.url
		anchor.innerText = result.label

		listItem.appendChild(anchor)

		return listItem
	}

	clearSearchResults() {
		this.resultList.hidden = true

		let child = this.resultList.firstElementChild

		while (child && child.matches('.is-search-result')) {
			this.resultList.removeChild(child)
			child = this.resultList.firstElementChild
		}
	}

	afterShow(event, immediate) {
		super.afterShow(event, immediate)

		this.input.focus()
	}

	afterHide(event, immediate) {
		super.afterHide(event, immediate)

		this.form.reset()
		/* this.clearSearchResults() */
	}
}

export default Search
