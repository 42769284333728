// import { trapFocus, unTrapFocus } from 'a11y-focus-trap'

/**
 * Wait for an event to finish
 * @param  {HTMLElement}     element   Element to apply the event listener to
 * @param  {string|boolean}  eventName Name of the event to await or `false`
 */
const awaitEvent = (element, eventName) => new Promise(resolve => {
	if (!eventName) return resolve()

	element.addEventListener(eventName, resolve, {
		once: true,
		passive: true
	})
})

/**
 * Check for iterability
 * @param  {*} obj
 */
const isIterable = (obj) => {
	return obj == null ? false : typeof obj[Symbol.iterator] === 'function'
}

/**
 * Convert anything to an array
 * @param  {*} obj
 */
const toArray = (obj) => {
	return obj == null ? [] : isIterable(obj) ? [...obj] : [obj]
}


/** Helper class for showing/hiding elements */
class Revealer {

	static get defaults() {
		return {
			trigger:      undefined,
			await:        'animationend',   // 'animationend' | 'transitionend' | false
			hidden:       true,
			onBeforeShow: () => {},
			onAfterShow:  () => {},
			onBeforeHide: () => {},
			onAfterHide:  () => {}
		}
	}

	/**
	 * Create a revealer instance
	 * @param {HTMLElement}       element
	 * @param {RevealerDefaults}  config
	 */
	constructor(element, config) {
		this.element = element
		this.config  = Object.assign({}, Revealer.defaults, config)

		// Bind this
		this.show = this.show.bind(this)
		this.hide = this.hide.bind(this)
		this.toggle = this.toggle.bind(this)

		this.init()
	}

	get hidden() {
		return this.element.hidden
	}

	/**
	 * Initialize
	 */
	init() {
		/** @type {HTMLElement[]} */
		this.trigger = toArray(
			(typeof this.config.trigger == 'string')
				? document.querySelectorAll(this.config.trigger)
				: this.config.trigger
		)

		this.element.hidden = Boolean(this.config.hidden)

		if (this.trigger) {
			document.addEventListener('click', this.handleClick.bind(this))
		}
	}

	/**
	* Handle trigger clicks
	 * @param {MouseEvent} event
	 */
	handleClick(event) {
		const path = getEventPath(event)

		// Check event.path for any trigger elements
		if (this.trigger.find(trigger => path.includes(trigger))) {
			this.toggle(...arguments)
		}
	}

	/**
	 * Called before showing the element
	 * @param {Event|undefined} event
	 */
	beforeShow(event) {
		this.element.hidden = false
	}

	/**
	 * Called after showing the element
	 * @param {Event|undefined} event
	 */
	afterShow(event) {}

	/**
	 * Shows the element
	 * @param {Event|undefined} event
	 */
	show(event) {
		if (!this.hidden) return

		if (event && event['preventDefault']) {
			event.preventDefault()
		}

		this.beforeShow(...arguments)
		this.config.onBeforeShow.apply(this, ...arguments)

		awaitEvent(this.element, this.config.await).then(() => {
			this.afterShow(...arguments)
			this.config.onAfterShow.apply(this, ...arguments)
		})
	}

	/**
	 * Called before hiding the element
	 * @param {Event|undefined} event
	 */
	beforeHide(event) {}

	/**
	 * Called after hiding the element
	 * @param {Event|undefined} event
	 */
	afterHide(event) {
		this.element.hidden = true
	}

	/**
	 * Hides the element
	 * @param {Event|undefined} event
	 */
	hide(event) {
		if (this.hidden) return

		if (event && event['preventDefault']) {
			event.preventDefault()
		}

		this.beforeHide(...arguments)
		this.config.onBeforeHide.apply(this, ...arguments)

		awaitEvent(this.element, this.config.await).then(() => {
			this.afterHide(...arguments)
			this.config.onAfterHide.apply(this, ...arguments)
		})
	}

	/**
	 * Toggle between show and hide
	 */
	toggle() {
		this.hidden ? this.show(...arguments) : this.hide(...arguments)
	}
}


function getEventPath(e) {
	function polyfill() {
		let element = e.target || null;
		const pathArr = [element];

		if (!element || !element.parentElement) {
			return [];
		}

		while (element.parentElement) {
			element = element.parentElement;
			pathArr.unshift(element);
		}

		return pathArr;
	}

	return e.path || (e.composedPath && e.composedPath()) || polyfill();
}

/**
 * Mixin that extends the Revealer class, adding classnames to the element
 * @param  {Revealer} superclass
 * @return {Revealer}
 */
export function withClassnames(superclass) {

	/**
	 * @alias Revealer
	 */
	return class extends superclass {
		beforeShow() {
			super.beforeShow(...arguments)
			window.setTimeout(() => {
				this.element.classList.add('is-opening')
				this.trigger.forEach(trigger => trigger.classList.add('is-open'))
			}, 10)
		}

		afterShow() {
			super.afterShow(...arguments)
			this.element.classList.remove('is-opening')
			this.element.classList.add('is-open')
		}

		beforeHide() {
			super.beforeHide(...arguments)
			this.element.classList.add('is-closing')
			this.trigger.forEach(trigger => trigger.classList.remove('is-open'))
		}

		afterHide() {
			window.setTimeout(() => {
				this.element.classList.remove('is-closing')
				this.element.classList.remove('is-open')
			}, 10)
			super.afterHide(...arguments)
		}
	}
}

/**
 * Mixin that extends the Revealer class, adding basic a11y
 * @param  {Class} superclass
 * @return {Class}
 */
export function withA11y(superclass) {

	/**
	 * @alias Revealer
	 */
	return class extends superclass {
		init() {
			super.init()

			this.trigger.forEach(trigger => {
				trigger.setAttribute('aria-haspopup', true)
				trigger.setAttribute('aria-expanded', !this.hidden)
				trigger.setAttribute('aria-controls', this.element.id)
			})
		}

		handleKeyDown(event) {
			if (event.keyCode === 27) { // `esc` key
				this.hide(...arguments)
			}
		}

		beforeShow(event) {
			super.beforeShow(...arguments)

			this.element.addEventListener('keydown', this.handleKeyDown.bind(this))

			// trapFocus(this.element, document.activeElement)
			this.trigger.forEach(trigger => trigger.setAttribute('aria-expanded', true))
		}

		beforeHide() {
			super.beforeHide(...arguments)

			this.element.removeEventListener('keydown', this.handleKeyDown)

			// unTrapFocus()
			this.trigger.forEach(trigger => trigger.setAttribute('aria-expanded', false))
		}
	}
}

export function withClose(superclass) {

	return class RevealWithClose extends superclass {

		static get defaults() {
			return Object.assign({}, superclass.defaults, {
				close: '[data-close]'
			})
		}

		constructor(element, config) {
			super(element, Object.assign({}, RevealWithClose.defaults, config))
		}

		handleClick(event) {
			super.handleClick(event)

			const close = [...this.element.querySelectorAll(this.config.close)]
			const path = getEventPath(event)

			// Check event.path for any trigger elements
			if (close.find(close => path.includes(close))) {
				this.hide(...arguments)
			}
		}
	}
}


export default Revealer
