// This needs to be imported first
import './public-path'

// Polyfills
import 'focus-visible'
import 'details-polyfill'

// Local modules
import { setWindowHeight, setBlockedHeight, throttle } from './helpers'
import CookieLayer from './cookie-layer'
import Header from './header'
import Footer from './footer'
import Slider from './slider'
import IndexList from './index-list'
import Modal from './modal'


/**
 * INIT
 *
 * This function initializes all modules used on the site, try to keep it as
 * simple as possible moving any complicated logic into the modules itself
 */
const Init = function() {
	CookieLayer.init()
	Header.init()
	Footer.init()
	Slider.init()
	IndexList.init()
	Modal.init()

	/**
	 * Reference to the FAQs container
	 */
	const faqRootElement = document.getElementById('faq')

	/**
	 * FAQs are quite heavy in package size, to keep the initial boundle size
	 * low we load the additional requirements asynchonously only when the
	 * FAQ container element exists on the current page.
	 */
	const Faq = new Promise((resolve, reject) => {
		if (faqRootElement) {
			import('./Faq').then(({ default: module }) => resolve(module))
		} else {
			reject({
				type:    'info',
				message: 'Skipping import of Faq module, faqRootElement not found.'
			})
		}
	})

	Faq.then(module => {
		module.init({
			appRoot: faqRootElement,
			searchRoot: document.getElementById('faq-search')
		})

		window.OASE.Faq = module
	}).catch(e => {})


	/**
	 * Expose all loaded packages globally under the `OASE` domain for runtime
	 * access. Make sure to inititalize all modules before this assignment.
	 */
	window.OASE = {
		CookieLayer,
		Header,
		Footer,
		Slider,
		IndexList,
		Modal
	}
}

// Initialize as soon as the DOM content has been loaded
document.addEventListener('DOMContentLoaded', Init)

// Have a throtteled function that fires on resize
const onResize = throttle(function() {
	setWindowHeight()
	setBlockedHeight()
	Footer.watch()
}, 250)

window.addEventListener('resize', onResize)
window.addEventListener('load', onResize)
