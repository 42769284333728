import Flickity from 'flickity'
import 'flickity/css/flickity.css'
import 'flickity-fade'
import 'flickity-fade/flickity-fade.css'
import 'flickity-fullscreen'
import 'flickity-fullscreen/fullscreen.css'


const Slider = function() {
	let elements = []
	let instances = []

	/**
	 * Overloaded watchCSS function that checks the :after property of the first
	 * slider cell instead of the slider container, this enables to
	 * activate/deactivate the slider by number of cells from CSS
	 */
	function overloadedWatchCSS() {
		const watchOption = this.options.watchCSS
		if (!watchOption) {
			return
		}

		const firstSliderElement = this.cells
			? this.getCellElements()[0]
			: this.element.firstElementChild

		const computedStyle = getComputedStyle(firstSliderElement, '::after')
		const afterContent = computedStyle.getPropertyValue('content')

		// activate if :after { content: 'flickity' }
		if (afterContent.indexOf('flickity') != -1) {
			this.activate()
		} else {
			this.deactivate()
		}
	}

	function init() {
		elements = [...document.querySelectorAll('[data-slider]')]

		elements.forEach(element => {
			const settings = {
				arrowShape: 'M 65,100 L 35,50 L 65,0',
				autoPlay: false,
				cellAlign: 'left',
				contain: true,
				fade: false,
				pageDots: false,
				pauseAutoPlayOnHover: true,
				prevNextButtons: true,
				watchCSS: true
			}

			// Parse settings applied via data attribute
			if (element.dataset.slider) try {
				Object.assign(settings, JSON.parse(element.dataset.slider))
			} catch (error) {
				console.log(error)
			}

			// Force disable slider controls when there is only one slide
			if (element.childElementCount <= 1) {
				settings.pageDots = false
				settings.prevNextButtons = false
			}

			if (settings.pageDots) {
				element.classList.add('has-pagedots')
			}

			// Initialize flickity
			const instance = new Flickity(element, settings)
			instance.watchCSS = overloadedWatchCSS

			instances.push(instance)
		})

		window.addEventListener('load', () => {
			instances.forEach(instance => {
				instance.resize()
				instance.watchCSS()
			})
		}, { once: true })
	}

	function destroy() {
		elements = []
		instances = []
		instances.forEach(instance => instance.destroy())
	}

	return { init, destroy, Flickity }
}()

export default Slider
