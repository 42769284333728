import { STATE_OPEN, STATE_ACTIVE } from '../constants'
import { debounce, removeStyle, isMobileNav } from '../helpers'
import MenuBase from './base'

class Menu extends MenuBase {


	init() {
		super.init()

		// add a single event listener on the menu element
		this.element.addEventListener('click', event => {
			const sibling = event.target.nextElementSibling
			if (sibling && this.isMenuLevel.call(this, sibling)) {
				event.preventDefault()
				this.onMenuLevelClick(event.target.parentElement)
			}
		});

		// create a breadcrumb for each menu level
		this.getMenuLevels().forEach(element => {
			const breadcrumb = this.createBreadcrumb(element)
			const menu = element.querySelector('ul')
			menu.insertBefore(breadcrumb, menu.firstChild)
		})

		// Debounce resize handler
		this.handleResize = debounce(this.handleResize.bind(this), 250)

		window.addEventListener('resize', this.handleResize)
	}



	/**
	 * Gets all siblings of the given element
	 * @param  {HTMLElement}   element
	 * @return {HTMLElement[]}
	 */
	getSiblings(element) {
		return Array.from(element.parentElement.children).filter(child => child !== element)
	}



	/**
	 * Test if the given element is a menu level
	 * @param  {HTMLElement} element
	 * @return {boolean}
	 */
	isMenuLevel(element) {
		return Boolean(
			[...this.element.querySelectorAll('li > ul')].find(el => el === element)
		)
	}



	/**
	 * Returns an array of all menu levels up the tree
	 * @param  {HTMLElement}   element
	 * @return {HTMLElement[]}
	 */
	getMenuLevelPath(element) {
		const path = []
		while (element) {
			if (element === this.element) {
				return path
			}
			if (element.tagName.toLowerCase() === 'li') {
				path.unshift(element)
			}
			element = element.parentElement
		}
	}



	/**
	 * Returns an array of all menu levels inside the given element (including nested ones)
	 * @param  {HTMLElement}   element
	 * @return {HTMLElement[]}
	 */
	getMenuLevels(element = this.element) {
		return [...element.querySelectorAll('ul > li')].filter(menu => {
			const submenus = Array.from(menu.children).filter(this.isMenuLevel.bind(this))
			return submenus.length
		})
	}



	/**
	 * Close all menu levels inside the given element
	 * @param {HTMLElement} element
	 * @param {boolean}     self    Close all menu levels including given level
	 */
	closeMenuLevels(element = this.element, self = false) {
		if (self && this.isMenuLevel.bind(this)) {
			element.classList.remove(STATE_OPEN)
			removeStyle(element.parentElement, 'overflow')
			element.offsetHeight // trigger repaint
		}

		this.getMenuLevels(element).forEach(element => {
			element.classList.remove(STATE_OPEN)
			removeStyle(element.parentElement, 'overflow')
			element.offsetHeight // trigger repaint
		})
	}



	/**
	 * @param  {HTMLElement} element
	 * @return {void}
	 */
	onMenuLevelClick(element) {
		// Close all sibling menus
		this.getSiblings(element).forEach(sibling => {
			this.closeMenuLevels(sibling, true)
		})

		// Open or close the current menu
		const submenuOpen = element.classList.toggle(STATE_OPEN)

		if (submenuOpen) {
			// Menu has been opened, check if we need to hide overflow on the parent
			this.handleMenuLevelOverflow(element.parentElement)
		} else {
			// Close all submenus if menu current menu was closed
			this.closeMenuLevels(element)
		}
	}



	/**
	 * Handles disabling and enabling overflow on menu levels that can scroll
	 * @param {HTMLElement} element
	 */
	handleMenuLevelOverflow(element) {
		const isScrollable = element.scrollHeight > element.offsetHeight

		if (isScrollable && isMobileNav()) {
			element.scrollTop = 0
			element.style.overflow = 'hidden'
		} else {
			removeStyle(element, 'overflow')
			element.offsetHeight // trigger repaint
		}
	}



	/**
	 * Create a breadcrumb for the given menu element
	 * @param {HTMLElement} menu  The element to generate the breadcrumb for
	 * @example // Creates the following markup:
	 *	<li class="nav__item header__breadcrumb">
	 *		<nav class="nav nav--inline nav--dense breadcrumb">
	 *			<button class="btn nav__item nav__link breadcrumb__item breadcrumb__item--base">Menü</button>
	 *			<button class="btn nav__item nav__link breadcrumb__item">Outdoor</button>
	 *			<button class="btn nav__item nav__link breadcrumb__item is-current">Teichbau</button>
	 *		</nav>
	 *	</li>
	 * @returns {HTMLElement}
	 */
	createBreadcrumb(menu) {
		const navItem = document.createElement('li')
		navItem.classList.add('nav__item', 'header__breadcrumb')

		const breadcrumb = document.createElement('nav')
		breadcrumb.classList.add('nav', 'nav--inline', 'nav--dense', 'breadcrumb')

		/**
		 * Text of the last menu item, will be overwritten by the following loop
		 * @type {string}
		 */
		let previousMenuText = (() => {
			const labelElement = this.trigger.find(trigger => {
				return trigger.hasAttribute('aria-label') || trigger.textContent !== ''
			})

			// Find the first trigger that has an `aria-label` attribute
			// and use the value as name for the first breadcrumb item
			if (labelElement.hasAttribute('aria-label')) {
				return labelElement.getAttribute('aria-label')
			}

			// If none found, use the first trigger that has text content
			if (labelElement.textContent !== '') {
				return labelElement.textContent
			}

			// As final resort use the unicode symbol for "house" (U+2302)
			return '⌂'
		})()

		const breadcrumbItemClassnames = ['btn', 'nav__item', 'nav__link', 'breadcrumb__item']

		/**
		 * Create a breadcrumb item for each menu level and append it
		 * to the breadcrumb element
		 */
		this.getMenuLevelPath(menu).forEach((element, index) => {
			const navLink = document.createElement('button')
			navLink.classList.add(...breadcrumbItemClassnames)

			if (!index) {
				// Add additional class to the first breadcrumb element
				navLink.classList.add('breadcrumb__item--base')
			}

			navLink.textContent = previousMenuText

			// Close the menu level on click and prevent the event from
			// bubbling up the tree to avoid side-effects
			navLink.onclick = event => {
				event.preventDefault()
				event.stopPropagation()

				this.closeMenuLevels(element, true)
			}

			breadcrumb.appendChild(navLink)

			// Save menu text for next element in breadcrumb
			previousMenuText = element.firstElementChild.textContent
		})

		// Finally, create the current breadcrumb item
		const currentItem = document.createElement('span')
		currentItem.classList.add(...breadcrumbItemClassnames, 'is-current')
		currentItem.textContent = previousMenuText

		breadcrumb.appendChild(currentItem)
		navItem.appendChild(breadcrumb)

		return navItem
	}



	beforeShow() {
		super.beforeShow(...arguments)

		// Make the menu start opened at the current place
		this.getMenuLevels().forEach(element => {
			if (element.classList.contains(STATE_ACTIVE)) {
				this.onMenuLevelClick(element)
			}
		})
	}



	beforeHide() {
		super.beforeHide(...arguments)

		// Close all submenus
		this.closeMenuLevels()
	}



	handleResize() {
		this.getMenuLevels().forEach(element => {
			// Check the parent menu levels of all open menu levels if they are
			// scrollable and if so, disable overflow on these elements
			if (element.classList.contains(STATE_OPEN)) {
				this.handleMenuLevelOverflow(element.parentElement)
			}
		})
	}


}

export default Menu
