import Gumshoe from 'gumshoejs'
import SmoothScroll from 'smooth-scroll'
import Flickity from 'flickity'
import 'flickity/css/flickity.css'

import { STATE_ACTIVE } from './constants'
import { getBlockedHeight } from './helpers'


const IndexList = function() {
	const selector = '[data-index-list]'

	let elements = []
	let instances = []

	let scrollSpy, smoothScroll

	function init() {
		elements = [...document.querySelectorAll(selector)]

		if (!elements.length) return

		elements.forEach(element => {
			const settings = {
				pageDots: false,
				freeScroll: true,
				contain: true,
				prevNextButtons: false,
				watchCSS: true
			}

			// Initialize flickity
			const instance = new Flickity(element, settings)
			instances.push(instance)

			// Fire `resize` and `watchCSS` methods as early as possible
			instance.resize()
			instance.watchCSS()
		})

		/**
		 * Watch the scroll position and sync the index's active state
		 * with the section that is currently in view
		 */
		scrollSpy = new Gumshoe(`${selector} a`, {
			navClass: STATE_ACTIVE,
			offset: getBlockedHeight,
			events: true
		})

		/**
		 * Smooth scrolling to anchor on nav link clicks
		 */
		smoothScroll = new SmoothScroll(`${selector} a`, {
			speed: 300,
			speedAsDuration: true,
			offset: getBlockedHeight
		});

		/**
		 * Listen for Gumshoe activate events to update the hash in the
		 * url bar and select the correct cell in the index slider
		 */
		document.addEventListener('gumshoeActivate', function(event) {
			updateHash(event.detail.link.getAttribute('href'))

			instances.forEach(instance => {
				if (instance.isActive) {
					instance.selectCell(`.${STATE_ACTIVE}`)
				}
			})
		}, false)

		window.addEventListener('load', () => {
			instances.forEach(instance => {
				instance.resize()
				instance.watchCSS()
			})
		}, { once: true })
	}

	/**
	 * Updates the hash of the current page
	 */
	function updateHash(hash) {
		if ((''+hash).charAt(0) !== '#') {
			hash = '#' + hash
		}
		history.replaceState('', '', hash)
	}

	function destroy() {
		instances.forEach(instance => {
			instance.destroy()
		})

		scrollSpy.destroy()
		smoothScroll.destroy()

		elements = []
		instances = []
	}

	return { init, destroy }
}()

export default IndexList
