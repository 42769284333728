import MicroModal from 'micromodal'
import { disableBodyScrolling, enableBodyScrolling } from './helpers';


const config = {
	onShow: disableBodyScrolling,
	onClose: enableBodyScrolling,
	openTrigger: 'data-modal-open',
	closeTrigger: 'data-modal-close',
	disableScroll: false,
	awaitCloseAnimation: true,
}

export default {
	init: () => MicroModal.init(config),
	show: MicroModal.show,
	close: MicroModal.close
}
