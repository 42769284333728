/**
 * Dedicated module for setting `publicPath` on the fly
 * @see https://webpack.js.org/guides/public-path/#on-the-fly
 */

const metaScriptPathElement = document.querySelector('meta[name="X-Script-Path"]')

if (metaScriptPathElement) {
	__webpack_public_path__ = metaScriptPathElement.getAttribute('content')
}

export default __webpack_public_path__
