import Cookies from 'js-cookie'

const CookieLayer = function() {

	const cookieName = 'cookiesAccepted'
	let element

	function init() {
		element = document.getElementById('notice_cookie-layer')

		if (element && Cookies.get(cookieName) === 'true') {
			element.remove()
		}
	}

	return {
		init
	}
}()

export default CookieLayer
